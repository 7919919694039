
import { createRouter } from '@tanstack/react-router'
import { routeTree } from '@/routeTree.gen'


export const router = createRouter({ routeTree, context: { 
    userId: null, 
    isAuthInitiating: true,
} })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}