import { LexoRank } from 'lexorank'
import { db } from '@/services/api'
import { create } from 'zustand'
import { useBoardsStore } from './boards'
import { useUser } from './user'
import { useProjectsStore } from './projects'

// The duration of task.isTransitioningFromStatus is kept. This is the time we give the UI to render the transition.
const STATUS_TRANSITION_DURATION = 1000
const SELECT_FIELDS = '*, projects!inner (board_id,owner_id,deleted_at), subtasks (*)'

export interface Subtask {
    id: string
    label: string
    is_completed: boolean
    created_at: Date
    updated_at: Date | null
    deleted_at: Date | null

    // Virtual fields.
    hasChanged?: boolean
    isRemoved?: boolean
    isNew?: boolean
}

export interface Task {
    id: string
    label: string
    status: 0|1|2
    project_id: string
    owner_id: string
    position: string
    description: string
    created_at: Date
    updated_at: Date | null
    deleted_at: Date | null
    subtasks: Subtask[]

    // Virtual fields.
    // Indicates if the task is currently changing its status. This can be used to render any transitions before the task might finally be removed from the list.
    // We will set this flag to the previous status for a short period of time when the task is transitioning between statuses.
    isTransitioningFromStatus?: Task['status']|null
}

interface TasksStore {
    tasks: Task[]
    isLoading: boolean
}

interface TaskStoreActions {
    refresh: () => void
    createTask: (task?: Partial<Task>) => void
    updateTask: (taskId: string, task: Partial<Task>) => void
    moveTask: (taskId: string, destinationIndex: number, sourceIndex: number) => void
    deleteTask: (taskId: string) => void
    restoreTask: (taskId: string) => void
}

export const useTasksStore = create<TasksStore & TaskStoreActions>((set, get) => ({
    isLoading: false,
    tasks: [],
    refresh: async () => {
        const { selectedBoardId } = useBoardsStore.getState()
        const { user } = useUser.getState()

        if (!user) {
            return 
        }

        if (!selectedBoardId) {
            set({ tasks: [], isLoading: false })
            return
        }

        set({ isLoading: true })
        
        let { data: tasks } = await db.from('tasks')
            .select(SELECT_FIELDS)
            .eq('owner_id', user.id)
            .eq('projects.board_id', selectedBoardId)
            .eq('projects.owner_id', user.id)
            // @todo We should filter out completed projects here to enhance performance. However currently we need
            // them in the list so that filtering by project works (which should show all tasks of the project, including finished).
            // .neq('status', 2) // Exclude completed tasks.
            .is('projects.deleted_at', null)
            .is('deleted_at', null)
            .order('position', { ascending: true })
            .returns<Task[]>()

        if (!tasks) tasks = []
        set({ tasks, isLoading: false })
    },

    createTask: async (task = {}) => {
        if (!task.project_id) {
            throw Error('Cannot create task without project')
        }

        const tasksOfProject = get().tasks.filter(t => t.project_id === task.project_id)
        let position = ''
        if (tasksOfProject.length) {
            // Get the last task of the project so we can insert the new task after it.
            const lastTask = tasksOfProject[tasksOfProject.length - 1]
            // Get the next task (regardless of the project) so we can calculate the new task's position.
            const nextTask = get().tasks[get().tasks.indexOf(lastTask) + 1]
            if (lastTask && nextTask) {
                if (lastTask.position === nextTask.position) {
                    // Well this is awkward. The last task and the next task have the same position.
                    // This might happen in some edge cases, but we can't really do anything about it.
                    // Generate a new rank for the next task on the fly to fix this.
                    const nextPosition = LexoRank.parse(nextTask.position).genNext().toString()
                    await get().updateTask(nextTask.id, { position: nextPosition })
                    nextTask.position = nextPosition
                }
                // Generate a new rank between the last task of the project and the next task (if any).
                position = LexoRank.parse(lastTask.position).between(LexoRank.parse(nextTask?.position)).toString()
            } else {
                // If there are no tasks after the last task of the project, generate a rank at the end of the list.
                position = LexoRank.parse(lastTask.position).genNext().toString()
            }
            
        } else {
            // If there are no tasks in the project, generate a rank at the beginning of the list.
            position = LexoRank.middle().toString()
        }

        task = {
            owner_id: useUser.getState().user?.id,
            position,
            ...task, 
            created_at: new Date() 
        }

        const { data } = await db.from('tasks')
            .insert(filterTaskFields(task as Task))
            .select(SELECT_FIELDS)
            .returns<Task[]>()

        const newTask = data?.[0]

        if (!newTask) {
            throw Error('Failed to create task')
        }

        if (task.subtasks) {
            newTask.subtasks = await handleSubtasks(newTask, task.subtasks)
        }

        set(({ tasks }) => ({ tasks: [ ...tasks, newTask] }))

        // When a new task is created we need to update the task count in the project.
        useProjectsStore.getState().updateTaskCounts(newTask.project_id, null, 0)
    },

    updateTask: async (taskId, nextState) => {
        const previousState = useTasksStore.getState().tasks.find(t => t.id === taskId)
        
        if (!previousState) {
            throw Error('Task not found in store')
        }
        
        // Complete the Partial<Task> into a full Task object.
        const task: Task = { ...previousState as Task, ...nextState }

        if (task.subtasks) {
            await handleSubtasks(task, task.subtasks)
        }

        // Update the task in the database. We don't update the full task object on purpose to avoid writing unwanted fields.
        // This also avoids manually removing virtual fields and relations from the task object (which would cause errors otherwise).
        const { data: results } = await db.from('tasks')
            .update(filterTaskFields({ ...task, updated_at: new Date() }))
            .eq('id', taskId)
            .select(SELECT_FIELDS)
            .returns<Task[]>()

        const updatedTask = results?.[0]


        if (!updatedTask) {
            throw Error('Failed to update task')
        }

        if (task.status !== previousState.status) {
            // When the status changes we need to set the isTransitioningFromStatus flag.
            updatedTask.isTransitioningFromStatus = previousState.status
            setTimeout(() => {
                set(({ tasks }) => ({ tasks: tasks.map(t => t.id === taskId ? { ...t, isTransitioningFromStatus: null } : t) }))
            }, STATUS_TRANSITION_DURATION)
        }

        set(({ tasks }) => ({ tasks: tasks.map(t => t.id === taskId ? { ...t, ...updatedTask } : t) }))

        // Everything below will take place after the UI has been updated.
        // --

        if (previousState?.status !== updatedTask.status) {
            // When the status changes we need to update the task count in the project.
            useProjectsStore.getState().updateTaskCounts(task.project_id, previousState.status, task.status)
        }

        if (previousState?.deleted_at !== task.deleted_at) {
            // When the task is deleted or restored we need to update the task count in the project.
            useProjectsStore.getState().updateTaskCounts(
                task.project_id, 
                // Only provide the previous status if the task has been removed.
                !previousState.deleted_at && task.deleted_at ? previousState.status : null,
                // Only provide the next status if the task has been restored.
                previousState.deleted_at && !task.deleted_at ? task.status : null
            )
        }

        if (previousState?.project_id !== task.project_id) {
            // When the task is moved to another project we need to update the task count in the projects.
            useProjectsStore.getState().updateTaskCounts(task.project_id, null, task.status)
            useProjectsStore.getState().updateTaskCounts(previousState.project_id, previousState.status, null)
        }
    },

    moveTask: async (taskId, sourceIndex, destinationIndex) => {
        const tasks = [ ...get().tasks ]
        const task = tasks.find(t => t.id === taskId)

        if (!task) {
            throw Error('Task not found in store')
        }

        
        tasks.splice(sourceIndex, 1)
        tasks.splice(destinationIndex, 0, task)
        // Persist the new order.
        set({ tasks })
        
        const previousPosition = tasks[destinationIndex - 1]?.position
        const nextPosition = tasks[destinationIndex + 1]?.position
        let position = LexoRank.middle().toString()

        if (previousPosition && nextPosition) {
            position = LexoRank.parse(previousPosition).between(LexoRank.parse(nextPosition)).toString()
        } else if (previousPosition) {
            position = LexoRank.parse(previousPosition).genNext().toString()
        } else if (nextPosition) {
            position = LexoRank.parse(nextPosition).genPrev().toString()
        }

        
        await get().updateTask(taskId, { position, })
    },

    deleteTask: async (taskId: string) => {
        // Use soft deletes to keep the data in the database for historical purposes.
        await get().updateTask(taskId, { deleted_at: new Date() })
    },

    restoreTask: async (taskId: string) => {
        await get().updateTask(taskId, { deleted_at: null })
    },
}))

async function handleSubtasks(task: Task, subtasks: Subtask[]) {
    // Keep all unchanged subtasks in the list.
    let updatedSubtasks = subtasks.filter(s => !s.isRemoved && !s.hasChanged && !s.isNew)

    // Remove subtasks that are not in the new list.
    const removedSubtasks = subtasks.filter(s => s.isRemoved)
    if (removedSubtasks.length) {
        await db.from('subtasks').delete().in('id', removedSubtasks.map(s => s.id))
    }

    const changedAndNewSubtasks = subtasks.filter(s => s.hasChanged || s.isNew).map(subtask => ({
        id: subtask.id,
        task_id: task.id,
        label: subtask.label.trim(),
        is_completed: subtask.is_completed,
        updated_at: subtask.hasChanged ? new Date() : null,
        deleted_at: subtask.isRemoved ? new Date() : null,
        owner_id: useUser.getState().user?.id,
    }))

    if (changedAndNewSubtasks.length) {
        const { data } = await db.from('subtasks')
            .upsert(changedAndNewSubtasks)
            .select()
            .returns<Subtask[]>()

        if (!data) {
            throw Error('Failed to update subtasks')
        }

        const newSubtasks = data as Subtask[]
        updatedSubtasks = [ ...updatedSubtasks, ...newSubtasks ]
    }

    return updatedSubtasks
}

// A utility function to filter out virtual fields and relations from the task object.
function filterTaskFields(task: Task) {
    return {
        id: task.id,
        label: task.label,
        status: task.status,
        project_id: task.project_id,
        owner_id: task.owner_id,
        position: task.position,
        description: task.description,
        updated_at: task.updated_at,
        deleted_at: task.deleted_at,
    }
}