import { Loader } from '@/components/Loader'
import { BoardForm } from '@/components/BoardForm'
import { BoardsEmptyState } from '@/components/BoardsEmptyState'
import { Header } from '@/components/Header'
import { ProjectForm } from '@/components/ProjectForm'
import { TaskForm } from '@/components/TaskForm'
import { useProjectForm } from '@/hooks/useProjectForm'
import { useTaskForm } from '@/hooks/useTaskForm'
import { useUser } from '@/state/user'
import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router'

const Component = () => {
    const isInitiating = useUser(({ isInitiating }) => isInitiating)
    const userId = useUser(({ user }) => user?.id)
    const hasAccess = useUser(({ profile }) => profile?.has_access)
    
    if (isInitiating) {
        return <Loader/>
    }

    if (!userId) {
        return <Navigate to="/login"/>
    }

    return <>
        {!hasAccess && <Navigate to="/subscribe"/>}
        <div className="app-container relative relative">
            <Header/>
            
            <Outlet/>

            <BoardsEmptyState/>
            <Forms/>

            <FadeOut/>
        </div>
    </>
}

export const Route = createFileRoute('/_authenticated')({
    component: Component,
})

// Neat little trick to create a fade out effect when the user scrolls down, got this from https://natt.sh/blog.
const FadeOut = () => {
    return <div className="pointer-events-none fixed bottom-0 isolate z-10 h-5 md:h-16 w-full">
        <div className="mask-t absolute inset-0 backdrop-blur-[1px]"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[2px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[3px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[5px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[8px] hidden md:block"></div>
        <div className="mask-t absolute inset-0 backdrop-blur-[10px]"></div>
    </div>
}

const Forms = () => {
    const { shouldMount: shouldTaskFormMount } = useTaskForm(({ shouldMount }) => ({ shouldMount }))
    const { shouldMount: shouldProjectFormMount } = useProjectForm(({ shouldMount }) => ({ shouldMount }))

    return <>
        {shouldTaskFormMount && <TaskForm/>}
        {shouldProjectFormMount && <ProjectForm/>}
        <BoardForm/>
    </>
}