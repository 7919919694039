import { Board } from '@/state/boards'

export const Wallpaper = ({ board, mode = 'fixed' }: { board: Board, mode?: 'fixed'|'absolute' }) => {
    const boardWallpaper = board.background_image_url || null
    const boardWallpaperColor = board.background_color || null
    
    if (!boardWallpaper && !boardWallpaperColor) return null

    return <>
        <div className={'inset-0 bg-white/50 dark:bg-black/70 z-[-1] ' + mode}/>
        <div className={'grain inset-0 z-[-2] opacity-60 ' + mode}/>
        <div 
            className={'inset-0 z-[-3] bg-center bg-cover ' + mode}
            style={{ backgroundImage: `url(${boardWallpaper})`, backgroundColor: boardWallpaperColor || ''}}/>
    </>
}