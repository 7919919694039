import { 
    closestCenter,
    DndContext, 
    DragEndEvent, 
    KeyboardSensor, 
    MouseSensor, 
    TouchSensor, 
    useDroppable, 
    useSensor, 
    useSensors 
} from '@dnd-kit/core'
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { Project, useProjectsStore } from '@/state/projects'
import { ProjectCard } from '../ProjectCard'

export const ProjectsList = () => {
    const focusedProjectId = useProjectsStore(({focusedProjectId}) => focusedProjectId)
    const isLoading = useProjectsStore(({isLoading}) => isLoading)
    const projects = useProjectsStore(({projects}) => projects.filter(p => !p.deleted_at))
    // const isLoading = useProjectsStore(({isLoading}) => isLoading)
    const { setNodeRef } = useDroppable({ id: 'projects', })
    
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 2,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 100,
                tolerance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const moveProject = useProjectsStore(({moveProject}) => moveProject)

    let classNames = 'project-list relative transition-opacity ease-in-out duration-300 px-3 mb-12 '
    if (focusedProjectId) {
        classNames += 'h-[60px] md:h-[70px]'
    }

    if (isLoading) return null
    
    return <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}>
        <div className={classNames} ref={setNodeRef}>
            <SortableContext 
                items={projects.map(p => p.id)}
                strategy={verticalListSortingStrategy}>
                <TransitionGroup component={null}>
                    {projects.map((project, index) => 
                        <CSSTransition key={project.id} unmountOnExit classNames={{
                            enter: 'opacity-0',
                            enterActive: 'opacity-100 transition-opacity ease-in duration-300',
                            exitActive: 'scale-0 transition-transform ease-out duration-500 delay-200',
                        }} timeout={1000}>
                            <DraggableCard project={project} index={index} />
                        </CSSTransition>
                    )} 
                </TransitionGroup>
            </SortableContext>
        </div>
    </DndContext>

    function handleDragEnd(e: DragEndEvent) {
        const { active, over } = e;

        const activeProject = projects.find(p => p.id === active.id)
        const overProject = projects.find(p => p.id === over?.id)

        if (!activeProject || !overProject) {
            // This should never happen.
            return
        }
        
        moveProject(
            activeProject.id,
            projects.indexOf(activeProject), 
            projects.indexOf(overProject)
        )
    }
}

const DraggableCard = ({ project, index }: { project: Project, index: number }) => {
    const focusedProjectId = useProjectsStore(({focusedProjectId}) => focusedProjectId)
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: project.id})

    const style = transform ? { transition, transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`, } : {}

    let classNames = 'project-card '
    if (focusedProjectId) {
        // @ts-expect-error -- no valid typing here.
        style.transformStyle = 'preserve-3d'
        style.transform = `translateY(calc(-${index} * 100%))`
        classNames += 'transition-transform ease-out duration-200 '

        if (project.id === focusedProjectId) {
            classNames += 'z-20 relative '
        }
    }
    
    return <div 
        ref={setNodeRef} 
        className={classNames}
        style={style}
        {...attributes} 
        {...listeners}>
        <ProjectCard project={project} />
    </div>
}