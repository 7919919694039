// This function attempts to replace the error messages from Supabase with translation keys.
// Might not always work as expected because the error messages can be quite varied.
// If you find a new error message that is not being replaced, please add it to the list.
// 
// Kindly,
// Marco
export const replaceSupabaseErrorMessages = (supabaseError: string) => {
    const errorTranslations: {[key: string]: string} = {
        'New password should be different from the old password.': 'new-password-should-be-different',
        'Password should be at least 6 characters long.': 'password-too-short',
        'Invalid login credentials': 'invalid-login-credentials',
        'missing email or phone': 'missing-email-or-phone',
    }

    return errorTranslations[supabaseError] || supabaseError
}