import { useEffect, useState } from 'react'

import { useMediaQuery } from '@/hooks/useMediaQuery'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from '@/components/ui/drawer'
import { useTranslation } from 'react-i18next'

interface ResponsiveDrawerProps {
    open: boolean
    onClose: () => void
    description?: string
    title?: string
    children?: React.ReactNode,
    actions?: React.ReactNode,
}

export const ResponsiveDrawer = (props: ResponsiveDrawerProps) => {
    const { t } = useTranslation()
    const [isOpened, setIsOpened] = useState(props.open)
    const isDesktop = useMediaQuery('(min-width: 768px)')

    useEffect(() => setIsOpened(props.open), [props.open])
    
    if (isDesktop) {
        return <Dialog open={isOpened} onOpenChange={(opened) => !opened && props.onClose()}>
            <DialogContent className="sm:max-w-[425px] md:min-w-[800px]">
                <DialogHeader className="mb-6">
                    <DialogTitle>{props.title}</DialogTitle>
                    <DialogDescription>{props.description}</DialogDescription>
                </DialogHeader>
                {props.children}
                {props.actions && <DialogFooter className="sm:justify-start mt-6 gap-2">
                    {props.actions}
                </DialogFooter>}
            </DialogContent>
        </Dialog>
    }
    
    return <Drawer open={isOpened} onOpenChange={(opened) => !opened && props.onClose()}>
        <DrawerContent className="flex flex-col fixed bottom-0 left-0 right-0 max-h-[96%]">
            <DrawerHeader className='text-left'>
                <DrawerTitle>{props.title}</DrawerTitle>
                <DrawerDescription>{props.description}</DrawerDescription>
            </DrawerHeader>
            <div className="max-w-md w-full mx-auto flex flex-col overflow-auto p-4">{props.children}</div>
            <DrawerFooter className="pt-2">
                <DrawerClose asChild>
                    <Button variant='outline'>{t('cancel-action')}</Button>
                </DrawerClose>
                {props.actions}
            </DrawerFooter>
        </DrawerContent>
    </Drawer>
}