import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { ResponsiveDrawer } from '../ResponsiveDrawer'
import { Board, useBoardsStore } from '@/state/boards'
import { useEffect, useState } from 'react'
import { useBoardForm } from '@/hooks/useBoardForm'
import { DeleteButton } from '../DeleteButton'
import { Trans, useTranslation } from 'react-i18next'
import { BackgroundSelector } from './BackgroundSelector'

export const BoardForm = () => {
    const { t } = useTranslation()
    const openedBoardId = useBoardForm(({ openedBoardId }) => openedBoardId)
    const boardFromStore = useBoardsStore(({ boards }) => boards.find(board => board.id === openedBoardId))
    const overloadFromForm = useBoardForm(({ overload }) => overload)
    const closeBoardForm = useBoardForm(({ closeBoardForm }) => closeBoardForm)
    const updateBoard = useBoardsStore(({ updateBoard }) => updateBoard)
    const createBoard = useBoardsStore(({ createBoard }) => createBoard)

    const [board, setBoard] = useState<Partial<Board>>({...boardFromStore, ...overloadFromForm,})
    useEffect(() => setBoard(b =>  ({...b, ...boardFromStore, ...overloadFromForm})), [boardFromStore, overloadFromForm])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!openedBoardId) return
        
        if (openedBoardId === 'new') {
            await createBoard(board)
        } else {
            await updateBoard(openedBoardId, { ...board })
        }

        closeBoardForm()
    }

    return <ResponsiveDrawer 
        open={!!openedBoardId}
        onClose={closeBoardForm}
        title={board?.id ? t('edit-board-title') : t('create-board-title')}
        description={board?.id ? t('edit-board-description') : t('create-board-description')}>
        <form className="grid items-start gap-4" onSubmit={handleSubmit}>
            <div className='grid gap-2'>
                <Label htmlFor="label">{t('label')}</Label>
                <Input 
                    type="text" 
                    id="label"
                    value={board.label} 
                    onChange={(e) => setBoard(board => ({ ...board, label: e.currentTarget.value }))} />
            </div>
            <div className='grid gap-2 pt-4'>
                <Label htmlFor="label">{t('board-background')}</Label>
                <BackgroundSelector 
                    onChange={handleBackgroundChange}
                    backgroundColor={board.background_color || ''}
                    backgroundImageUrl={board.background_image_url || ''}/>
                {board.background_image_url && <div className="text-sm text-gray-500">
                    <Trans 
                        t={t}
                        i18nKey="board-background-image-creator-credits" 
                        values={{  name: board.background_image_creator_name, link: board.background_image_creator_link }} 
                        components={{ a: <a /> }} />
                </div>}
            </div>
            <Button type="submit">
                {board?.id ? t('edit-board-submit') : t('create-board-submit')}
            </Button>
            <div>
                <DeleteBoardButton board={board} />
            </div>
        </form>
    </ResponsiveDrawer>

    function handleBackgroundChange({ 
        background_image_url, 
        background_color, 
        background_image_creator_link, 
        background_image_creator_name 
    }: Partial<Board>) {
        setBoard(board => ({ ...board, 
            background_image_url, 
            background_color, 
            background_image_creator_link, 
            background_image_creator_name
        }))
    }
}

const DeleteBoardButton = ({ board }: { board: Partial<Board> }) => {
    const deleteBoard = useBoardsStore(({ deleteBoard }) => deleteBoard)
    const closeBoardForm = useBoardForm(({ closeBoardForm }) => closeBoardForm)
    const { t } = useTranslation()

    const handleDelete = async () => {
        if (!board?.id) return
        await deleteBoard(board.id)
        closeBoardForm()
    }

    if (!board?.id) return null

    return <DeleteButton onDelete={handleDelete} label={t('delete-board-button')} />
}