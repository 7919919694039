import { Card, CardHeader, CardTitle } from '@/components/ui/card'
import { Wallpaper } from '@/components/Wallpaper'
import { Board, useBoardsStore } from '@/state/boards'
import { createFileRoute, Link } from '@tanstack/react-router'
import { useEffect } from 'react'

const Homepage = () => {
    const boards = useBoardsStore(({ boards }) => boards)

    useEffect(() => { useBoardsStore.getState().selectBoard(null) }, [])

    if (!boards.length) {
        return <></>
    }

    return <div className="px-4 relative flex-1 grow w-screen max-w-[800px] mx-auto">
        {boards.map(board => <BoardCard key={board.id} board={board} />)}
    </div>
}

const BoardCard = ({ board }: { board: Board }) => {
    return <Link to="/boards/$boardId" params={{ boardId: board.id }}>
        <Card className="z-0 overflow-hidden relative mb-2 md:mb-3 border-slate-600/50 dark:border-slate-200/20 border-2 flex flex-col relative group transition-all ease-in-out duration-100 bg-background">
            <Wallpaper board={board} mode="absolute" />
            <CardHeader className="p-3 md:p-3">
                <CardTitle className="text-base md:text-lg flex items-center justify-between">
                    {board.label}
                </CardTitle>
            </CardHeader>
        </Card>
    </Link>
}

export const Route = createFileRoute('/_authenticated/')({
    component: () => <Homepage />,
})
