import { create } from 'zustand'
import { db } from '../services/api'
import { useBoardsStore } from './boards'
import { AuthTokenResponse, UserResponse } from '@supabase/supabase-js'
import { router } from '@/router'

interface User {
    id: string
    name: string
    email: string
    createdAt: Date
    updatedAt: Date | null
}

interface Profile {
    id: string
    email: string
    name: string
    stripe_customer_id: string
    has_access: boolean
}

interface UserStore {
    user: User | null
    profile: Profile | null
    isResettingPassword: boolean,
    isAuthenticating: boolean,
    isInitiating: boolean
}

interface UserStoreActions {
    logout: () => void
}

export const useUser = create<UserStore & UserStoreActions>((set, get) => {
    const cleanUp = () => {
        set({ user: null, profile: null })
        useBoardsStore.getState().refresh()
    }

    const checkActiveSession = async () => {
        setTimeout(async () => {
            const response = await db.auth.getUser()
            try {
                // @ts-expect-error something
                await handleUserResponse(set, get, response)
            } catch (error) {
                console.error(error)
            } finally {
                set({ isInitiating: false })
            }

        }, 50)
    }

    let isCheckingSession = false

    db.auth.onAuthStateChange(async (event) => {
        if (['SIGNED_IN', 'PASSWORD_RECOVERY'].includes(event) && !get().user) {
            set({ isResettingPassword: event === 'PASSWORD_RECOVERY' })
            isCheckingSession = true
            await checkActiveSession()
        } else if (event === 'USER_UPDATED') {
            const wasResettingPassword = get().isResettingPassword
            if (wasResettingPassword) {
                set({ isResettingPassword: false })
                router.navigate({ to: '/' })
            }
        } else if (event === 'SIGNED_OUT') {
            cleanUp()
        }
    })

    setTimeout(() => {
        if (!isCheckingSession) {
            checkActiveSession()
        }
    }, 200)

    return {
        profile: null,
        user: null,
        isInitiating: true,
        isAuthenticating: false,
        isResettingPassword: false,

        logout: async () => {
            await db.auth.signOut()
            cleanUp()
        },
    }
})

const handleUserResponse = async (set: () => void, get: () => UserStore & UserStoreActions, response: AuthTokenResponse | UserResponse) => {
    const { data: { user }, error } = response
    if (error) {
        // This will have errors for simple stuff like not being logged in. I don't want to throw an error for that.
        // throw Error(error.message)
        return false
    }

    if (!user) {
        throw Error('User not found')
    }

    localStorage.setItem('user_email', user.email || '')

    const { data: profiles } = await db.from('profiles').select().eq('id', user.id).returns<Profile[]>()
    if (!profiles || profiles.length === 0) {
        throw Error('Profile not found')
    }

    const profile = profiles[0]

    // @ts-expect-error something
    set({
        isAuthenticating: false,
        user: {
            id: user.id,
            name: '',
            email: user.email || '',
            createdAt: new Date(user.created_at),
            updatedAt: user.updated_at ? new Date(user.updated_at) : null,
        },
        profile: {
            id: profile.id,
            email: profile.email,
            name: profile.name,
            stripe_customer_id: profile.stripe_customer_id,
            has_access: profile.has_access,
        }
    })

    // Make sure the rest of the application is being set up as well.
    useBoardsStore.getState().refresh()

    if (get().isResettingPassword) {
        router.navigate({ to: '/lost-password' })
        return
    }

    if (router.state.resolvedLocation.pathname.includes('/login')) {
        router.navigate({ to: '/' })
        return
    }
}