import { Board } from '@/state/boards'
import { CircleOff, PaintBucket, Image } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageDrawer, UnsplashImageResult } from './ImageDrawer'
import { ColorDrawer } from './ColorDrawer'

const activeStyle = (activeMode: string, mode: string) => {
    if (activeMode === mode) {
        return 'outline outline-2 outline-offset-2 outline-gray-600 opacity-100'
    }

    return 'opacity-40'
}

interface BackgroundSelectorProps {
    backgroundColor?: string
    backgroundImageUrl?: string
    onChange: (board: Partial<Board>) => void
}

export const BackgroundSelector = ({ 
    backgroundImageUrl: outerBackgroundImageUrl, 
    backgroundColor: outerBackgroundColor,
    onChange 
}: BackgroundSelectorProps) => {
    const { t } = useTranslation()

    const [backgroundImageUrl, setBackgroundImageUrl] = useState(outerBackgroundImageUrl || '')
    useEffect(() => setBackgroundImageUrl(outerBackgroundImageUrl || ''), [outerBackgroundImageUrl])

    const [backgroundColor, setBackgroundColor] = useState(outerBackgroundColor || '')
    useEffect(() => setBackgroundColor(outerBackgroundColor || ''), [outerBackgroundColor])

    const [mode, setMode] = useState(backgroundImageUrl ? 'image' : backgroundColor ? 'color' : 'none')
    useEffect(() => setMode(backgroundImageUrl ? 'image' : backgroundColor ? 'color' : 'none'), [backgroundImageUrl, backgroundColor])

    const [imageDrawerOpened, setImageDrawerOpened] = useState(false)
    const [colorDrawerOpened, setColorDrawerOpened] = useState(false)

    const baseStyle = "hover:opacity-100 cursor-pointer flex-1 bg-accent rounded-xl flex justify-center items-center overflow-hidden"
    const circleStyle = "p-4 bg-accent/80 rounded-full"
    
    return <>
        <div className="flex gap-4 h-24">
            <div 
                onClick={() => handleSelection('none')}
                className={" " + baseStyle + " " + activeStyle(mode, 'none')} 
                title={t('board-background-none')}>
                <div className={circleStyle}>
                    <CircleOff size={24} />
                </div>
            </div>
            <div 
                onClick={() => handleSelection('color')}
                className={"" + baseStyle + " " + activeStyle(mode, 'color')} 
                style={{ backgroundColor: backgroundColor }}
                title={t('board-background-color')}>
                <div className={circleStyle}>
                    <PaintBucket size={24} />
                </div>
            </div>
            <div 
                onClick={() => handleSelection('image')}
                className={"bg-center bg-cover bg-no-repeat " + baseStyle + " " + activeStyle(mode, 'image')} 
                title={t('board-background-image')}
                style={{ backgroundImage: backgroundImageUrl ? `url('${backgroundImageUrl}')` : '' }}>
                <div className={circleStyle}>
                    <Image size={24} />
                </div>
            </div>
        </div>
        <ColorDrawer 
            open={colorDrawerOpened} 
            onClose={() => setColorDrawerOpened(false)} 
            onChange={handleColorSelection} />
        <ImageDrawer 
            open={imageDrawerOpened} 
            onClose={() => setImageDrawerOpened(false)} 
            onChange={handleImageSelection}/>
    </>

    function handleColorSelection(color: string) {
        setBackgroundImageUrl('')
        onChange({ 
            background_image_url: null, 
            background_color: color, 
            background_image_creator_name: null, 
            background_image_creator_link: null 
        })
    }

    function handleImageSelection(image: UnsplashImageResult) {
        setBackgroundImageUrl(image.urls.full)
        onChange({ 
            background_color: null,
            background_image_url: image.urls.full,
            background_image_creator_name: image.user.name,
            background_image_creator_link: image.user.links.html,
        })
    }

    function handleSelection(mode: string) {
        if (mode === 'none') {
            onChange({ background_image_url: null, background_color: null, background_image_creator_name: null, background_image_creator_link: null })
        } else if (mode === 'color') {
            setColorDrawerOpened(true)
        } else if (mode === 'image') {
            setImageDrawerOpened(true)
        }
    }
}