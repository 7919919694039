import { 
    closestCenter,
    DndContext, 
    DragEndEvent, 
    KeyboardSensor, 
    MouseSensor, 
    TouchSensor, 
    useDroppable, 
    useSensor, 
    useSensors 
} from '@dnd-kit/core'
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { Task, useTasksStore } from '@/state/tasks'
import { TaskCard } from '../TaskCard'
import { useProjectsStore } from '@/state/projects'
import { CirclePlus, Layers } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Button } from '../ui/button'
import { useTaskForm } from '@/hooks/useTaskForm'

export const TasksList = () => {
    const { t } = useTranslation()
    const focusedProjectId = useProjectsStore(({focusedProjectId}) => focusedProjectId)
    let projects = useProjectsStore(({projects}) => projects.filter(p => !p.deleted_at))
    const tasks = useTasksStore(({tasks}) => tasks)
    const { setNodeRef } = useDroppable({ id: 'tasks', })

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const moveTask = useTasksStore(({moveTask}) => moveTask)

    if (focusedProjectId) {
        projects = projects.filter(p => p.id === focusedProjectId)
    }

    return <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}>
        <div className="task-list transition-opacity ease-in-out duration-300 relative grow px-3 md:px-12 mt-3 md:mt-5" ref={setNodeRef}>
            {projects.map((project, index) => <div key={project.id}>
                {index > 0 && <div className="mt-5 md:mt-10"/>}
                {!focusedProjectId && <h3 className="text-xs md:text-base mb-2 md:mb-4 opacity-80 dark:opacity-50 flex items-center justify-start">
                    <Layers className="inline w-4 h-4 mr-1 mb-[-2px]"/>
                    {project.label}
                    <span className="opacity-60 dark:opacity-50 ml-2">({project.num_tasks_done}/{project.num_tasks_total} {t('tasks')})</span>
                </h3>}
                {focusedProjectId === project.id && <NewTaskButton projectId={project.id} />}
                <TasksOfProject key={project.id} projectId={project.id} />
            </div>)}            
        </div>
    </DndContext>

    function handleDragEnd(e: DragEndEvent) {
        const { active, over } = e;

        const activeTask = tasks.find(p => p.id === active.id)
        const overTask = tasks.find(p => p.id === over?.id)

        if (!activeTask || !overTask) {
            // This should never happen.
            return
        }
        
        moveTask(
            activeTask.id,
            tasks.indexOf(activeTask), 
            tasks.indexOf(overTask)
        )
    }
}

const NewTaskButton = ({ projectId }: { projectId: string }) => {
    const { t } = useTranslation()
    const openTaskForm = useTaskForm(({openTaskForm}) => openTaskForm)

    return <Button 
        variant="outline" 
        className="block w-full mb-5"
        onClick={handleClick}>
            <CirclePlus className="h-4 w-4 mr-2 inline" />
        {t('create-new-task')}
    </Button>

    function handleClick (e: React.MouseEvent) {
        e.stopPropagation()
        openTaskForm('new', { project_id: projectId })
    }
}

const TasksOfProject = ({ projectId }: { projectId: string }) => {
    const focusedProjectId = useProjectsStore(({focusedProjectId}) => focusedProjectId)
    // On regular loaded lists we already filtered out deleted tasks, but recently deleted tasks are still in the list.
    // This is needed to allow quick and easy restoring via the undo button (and not having to reorder the list).
    // Hence, we need to filter out deleted tasks here as well.
    let tasks = useTasksStore(({tasks}) => tasks.filter(t => t.project_id === projectId && t.deleted_at === null))

    if (focusedProjectId !== projectId) {
        // Hide completed tasks when not focused (but don't hide transitioning tasks so that we can still do cool animations and stuff).
        // Have a look at the tasks store to see what's behind this - it basically allows a brief moment for the task to be animated before it's removed from the list.
        tasks = tasks.filter(t => !(t.status === 2 && !Number.isInteger(t.isTransitioningFromStatus)))
    }

    return <>
        <SortableContext 
            items={tasks.map(t => t.id)}
            strategy={verticalListSortingStrategy}>
            <TransitionGroup enter={!focusedProjectId} exit={!focusedProjectId} component={null}>
                {tasks.map((task) => 
                    <CSSTransition key={task.id} classNames={{
                        enter: 'opacity-0',
                        enterActive: 'opacity-100 transition-opacity ease-in duration-300',
                        exitActive: 'scale-0 transition-transform ease-out duration-500 delay-200',
                    }} timeout={1000}>
                        <DraggableCard key={task.id} task={task} />
                    </CSSTransition>
                )} 
            </TransitionGroup>
        </SortableContext>
    </>
}

const DraggableCard = ({ task }: { task: Task }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: task.id})

    const style = transform ? { transition, transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`, } : undefined;
    
    return <div 
        ref={setNodeRef} 
        style={style} 
        {...attributes} 
        {...listeners}>
        <TaskCard task={task} />
    </div>
}