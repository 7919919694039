import { Project } from '@/state/projects'
import { create } from 'zustand'

interface ProjectFormState {
    overload: Partial<Project> | null
    openedProjectId: 'new' | string | null
    shouldMount: boolean
    openProjectForm: (projectId: string, overload?: Partial<Project>) => void
    closeProjectForm: () => void
}

export const useProjectForm = create<ProjectFormState>((set) => ({
    overload: null,
    openedProjectId: null,
    shouldMount: false,
    openProjectForm: (projectId: string, overload) => set({
        openedProjectId: projectId,
        overload: overload ? overload : null,
        shouldMount: true,
    }),
    closeProjectForm: () => {
        set({ openedProjectId: null, overload: null })
        // Give some time for animations before unmounting the form.
        setTimeout(() => set({ shouldMount: false }), 300)
    },
}))