import { useState } from 'react'
import { Button } from '../ui/button'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog'
import { useTranslation } from 'react-i18next'
import { Trash } from 'lucide-react'

interface DeleteButtonProps {
    onDelete: () => Promise<void>
    label: string
    alertTitle?: string
    alertDescription?: string
    alertCancel?: string
    alertContinue?: string
}

export const DeleteButton = (props: DeleteButtonProps) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    props = { ...{
        alertTitle: t('delete-modal-title'),
        alertDescription: t('delete-modal-description'),
        alertCancel: t('cancel-delete-action'),
        alertContinue: t('confirm-delete-action'),
    }, ...props}

    const handleDelete = async () => {
        try {
            await props.onDelete()
            setIsOpen(false)
        } catch (error) {
            console.error(error)
        }
    }

    return <>
        <Button 
            className="p-0 m-0"
            onClick={(e) => { e.preventDefault(); setIsOpen(true)}} 
            variant="link">
            <Trash size={16} className="mr-2" />
            {props.label}
        </Button>

        <AlertDialog open={isOpen}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{props.alertTitle}</AlertDialogTitle>
                    <AlertDialogDescription>{props.alertDescription}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="sm:justify-start mt-4">
                    <AlertDialogAction 
                        className="bg-red-900 hover:bg-red-700 text-white" 
                        onClick={handleDelete}>
                        <Trash size={16} className="mr-2" />
                        {props.alertContinue}
                    </AlertDialogAction>
                    <AlertDialogCancel onClick={() => setIsOpen(false)}>{props.alertCancel}</AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    </>
}