import { BoardList } from './BoardList'
import { UserMenu } from './UserMenu'
import { NewProjectButton } from './NewProjectButton'

export const Header = () => {
    return <>
        <div className="z-10 fixed top-0 backdrop-blur-lg w-screen">
            <div className="w-screen max-w-[800px] mx-auto">
                <header className="relative my-0 mx-3 md:mx-4 z-20 flex justify-between gap-2 md:gap-4 items-center pt-2 pb-4 md:pt-6 md:pb-6 px-2 md:px-0">
                    <BoardList />
                    <div className="flex-1" />
                    <NewProjectButton />
                    <UserMenu />
                </header>
            </div>
            <div className="absolute inset-0 z-[-1] bg-gradient-to-b from-20% from-[hsl(var(--background))]" />
        </div>
        <div className="pt-16 md:pt-24" />
    </>
}