import { db } from '@/services/api'
import { create } from 'zustand'
import { useProjectsStore } from './projects'
import { useTasksStore } from './tasks'
import { useUser } from './user'

export interface Board {
    id: string
    label: string
    owner_id: string
    background_image_url: string | null
    background_image_creator_name: string | null
    background_image_creator_link: string | null
    background_color: string | null
    created_at: Date
    updated_at: Date | null
    deleted_at: Date | null
}

interface BoardsStore {
    isLoading: boolean,
    boards: Board[],
    selectedBoardId: string | null,
    createBoard: (board?: Partial<Board>) => void,
    updateBoard: (boardId: string, board: Partial<Board>) => void,
    deleteBoard: (boardId: string) => void,
    selectBoard: (id: string | null) => void,
    refresh: () => void,
}

export const useBoardsStore = create<BoardsStore>((set, get): BoardsStore => ({
    isLoading: false,
    boards: [],
    selectedBoardId: null,

    selectBoard: (id) => {
        set({ selectedBoardId: id, })

        localStorage.setItem('selected_board_id', id || '')

        useProjectsStore.getState().refresh()
        useTasksStore.getState().refresh()
    },

    refresh: async () => {
        const { user } = useUser.getState()

        if (!user) {
            // throw Error('Cannot fetch boards without user')
            set({ boards: [], isLoading: false })
            get().selectBoard(null)
            return
        }

        set({ isLoading: true })
        
        let { data: boards } = await db.from('boards').select()
            .eq('owner_id', user.id)
            .is('deleted_at', null)
            .returns<Board[]>()

        if (!boards) boards = []
        set({ boards, isLoading: false })

        if (!boards.length) {
            set({ selectedBoardId: null })
            localStorage.removeItem('selected_board_id')
            return
        }

        /*
        const selectedBoardId = get().selectedBoardId || localStorage.getItem('selected_board_id')

        if (!selectedBoardId || !boards.find(board => board.id === selectedBoardId)) {
            // If the selected board is not set or the selected board is not in the list of boards, set the first board as selected.
            get().selectBoard(boards[0]?.id || null)
        } else if (selectedBoardId !== get().selectedBoardId) {
            // If the selected board is cached but is different from the one in the store, set the selected board.
            get().selectBoard(selectedBoardId)
        }
        */
    },

    createBoard: async (board) => {
        board = {
            owner_id: useUser.getState().user?.id,
            ...board, 
            created_at: new Date() 
        }

        const { data } = await db.from('boards').insert(board).select().returns<Board[]>()
        const newBoard = data?.[0]

        if (!newBoard) {
            throw Error('Failed to create board')
        }

        set(({ boards }) => ({ boards: [ ...boards, newBoard] }))

        if (!get().selectedBoardId) {
            get().selectBoard(newBoard.id)
        }
    },

    updateBoard: async (boardId, board) => {
        board = { updated_at: new Date(), ...board }

        const { data } = await db.from('boards').update({
            label: board.label,
            background_image_url: board.background_image_url,
            background_image_creator_name: board.background_image_creator_name,
            background_image_creator_link: board.background_image_creator_link,
            background_color: board.background_color,
            updated_at: board.updated_at,
            deleted_at: board.deleted_at,
        }).eq('id', boardId).select().returns<Board[]>()

        const updatedBoard = data?.[0]

        if (!updatedBoard) {
            throw Error('Failed to update board')
        }

        set(({ boards }) => ({ boards: boards.map(b => b.id === boardId ? updatedBoard : b) }))
    },

    deleteBoard: async (boardId) => {
        // Use soft deletes for removal.
        await get().updateBoard(boardId, { deleted_at: new Date() })

        if (boardId === get().selectedBoardId) {
            // Make sure to select a board after the current one is deleted.
            get().selectBoard(get().boards[0]?.id || null)
        }

        set(({ boards }) => ({ boards: boards.filter(board => board.id !== boardId) }))
    },
}))