import { Loader } from '@/components/Loader'
import { Button } from '@/components/ui/button'
import { CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { db } from '@/services/api'
import { createFileRoute, Link } from '@tanstack/react-router'
import { Binary, Fish, KeySquare, Mail } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { useUser } from '@/state/user'
import { replaceSupabaseErrorMessages } from '@/lib/supabaseErrorMessages'

export const LostPassword = () => {
    const userId = useUser(({ user }) => user?.id)
    console.log('lost password is here')

    if (userId) {
        console.log('reset flow!')
        return <ResetFlow />
    }

    console.log('initiate flow')

    return <InitiateResetFlow />
}

const InitiateResetFlow = () => {
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [wasSuccessful, setWasSuccessful] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    return <>
        {isLoading && <Loader />}
        <CardContent className="p-4 pt-0">
            <h1 className="text-xl font-semibold mb-2">{t('forgot-password')}</h1>
            <p className="mb-5">{t('forgot-password-help')}</p>
            {!wasSuccessful && <form onSubmit={handleSubmit} autoComplete="on" className="grid gap-2">
                <Input
                    type="email"
                    id="email"
                    value={email}
                    autoComplete='email'
                    placeholder={t('email-placeholder')}
                    onChange={(e) => setEmail(e.currentTarget.value)} />
                <Button
                    type="submit"
                    disabled={isLoading}
                    className={"bg-yellow-400 hover:bg-yellow-600 text-black font-semibold " + (isLoading ? 'opacity-50' : '')}>
                    {!isLoading && <><Mail className="w-4 h-4 mr-2" /> {t('reset-password-button')}</>}
                    {isLoading && <><Binary className="w-4 h-4 mr-2" /> {t('reset-password-button-loading')}</>}
                </Button>
                {error && <div className="text-red-500">{error}</div>}
            </form>}
            {wasSuccessful && <>
                <Alert>
                    <Fish className="h-4 w-4" />
                    <AlertTitle>{t('forgot-password-success-title')}</AlertTitle>
                    <AlertDescription className="mt-2">
                        {t('forgot-password-success-message')}
                    </AlertDescription>
                </Alert>
            </>}
            <div className="mt-8 text-sm border-t pt-3">
                <Link to="/login" className="text-gray-400 hover:underline">
                    {t('remembered-password')}
                </Link>
            </div>
        </CardContent>
    </>

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setIsLoading(true)
        const { error } = await db.auth.resetPasswordForEmail(email, { redirectTo: window.location.href })
        setIsLoading(false)

        if (error) {
            setError(error.message)
        } else {
            setWasSuccessful(true)
        }
    }
}

const ResetFlow = () => {
    const { t } = useTranslation()
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    return <>
        {isLoading && <Loader />}
        <CardContent>
            <h1 className="text-xl font-semibold mb-2">{t('reset-password-title')}</h1>
            <p className="mb-5">{t('reset-password-help')}</p>
            <form onSubmit={handleSubmit} autoComplete="on" className="grid gap-2">
                <Input
                    type="password"
                    value={password}
                    autoComplete='new-password'
                    placeholder={t('new-password-placeholder')}
                    onChange={(e) => setPassword(e.currentTarget.value)} />
                <Input
                    type="password"
                    value={passwordConfirmation}
                    autoComplete='confirm-password'
                    placeholder={t('new-password-confirmation-placeholder')}
                    onChange={(e) => setPasswordConfirmation(e.currentTarget
                    .value)} />
                <Button
                    type="submit"
                    disabled={isLoading}
                    className={"bg-yellow-400 hover:bg-yellow-600 text-black font-semibold " + (isLoading ? 'opacity-50' : '')}>
                    {!isLoading && <><KeySquare className="w-4 h-4 mr-2" /> {t('set-new-password-button')}</>}
                    {isLoading && <><Binary className="w-4 h-4 mr-2" /> {t('set-new-password-button-loading')}</>}
                </Button>
                {error && <div className="text-red-500">{error}</div>}
            </form>
        </CardContent>
    </>

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setError(null)

        if (password !== passwordConfirmation) {
            setError(t('passwords-mismatch'))
            return
        }

        setIsLoading(true)
        const { error } = await db.auth.updateUser({ password })
        setIsLoading(false)

        if (error) {
            setError(t(replaceSupabaseErrorMessages(error.message)))
        }
    }
}

export const Route = createFileRoute('/_auth/lost-password')({
    component: LostPassword,
})
