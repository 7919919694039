import { Board } from '@/state/boards'
import { create } from 'zustand'

interface BoardFormState {
    overload: Partial<Board> | null
    openedBoardId: 'new' | string | null
    openBoardForm: (boardId: string, overload?: Partial<Board>) => void
    closeBoardForm: () => void
}

export const useBoardForm = create<BoardFormState>((set) => ({
    overload: null,
    openedBoardId: null,
    openBoardForm: (boardId, overload) => set({ 
        openedBoardId: boardId,
        overload: overload ? overload : null
    }),
    closeBoardForm: () => set({ 
        openedBoardId: null, 
        overload: null 
    }), 
}))