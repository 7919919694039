import { useBoardsStore } from '@/state/boards'
import { Button } from '../ui/button'
import { useProjectsStore } from '@/state/projects'
import { useProjectForm } from '@/hooks/useProjectForm'
import { useTranslation } from 'react-i18next'

export const ProjectsEmptyState = () => {
    const { t } = useTranslation()
    const isLoading = useProjectsStore(({isLoading}) => isLoading)
    const selectedBoardId = useBoardsStore(({selectedBoardId}) => selectedBoardId)
    const projects = useProjectsStore(({projects}) => projects)
    const openProjectForm = useProjectForm(({openProjectForm}) => openProjectForm)

    if (!selectedBoardId) return null
    if (isLoading) return null
    if (projects.length) return null
    
    return <div className="flex flex-col items-center justify-center absolute top-[-5rem] bottom-0 right-0 left-0">
        <div className="w-40 h-40 bg-gray-800 rounded-full mb-8 text-8xl flex flex-col items-center justify-center">🛠️</div>
        <h1 className="text-3xl font-bold mb-2">{t('empty-state-projects-title')}</h1>
        <p className="text-lg text-gray-500 w-4/5 text-justify leading-6">
            {t('empty-state-projects-description')}
        </p>
        <Button 
            className="mt-12 text-lg bg-gray-800 hover:bg-gray-700" 
            onClick={() => openProjectForm('new')}>
            {t('create-first-project-button')}
        </Button>
    </div>
}