import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { ResponsiveDrawer } from '../ResponsiveDrawer'
import { Project, useProjectsStore } from '@/state/projects'
import { useEffect, useState } from 'react'
import { useProjectForm } from '@/hooks/useProjectForm'
import { DeleteButton } from '../DeleteButton'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { Loader2 } from 'lucide-react'

export const ProjectForm = () => {
    const { t } = useTranslation()
    const openedProjectId = useProjectForm(({ openedProjectId }) => openedProjectId)
    const closeProjectForm = useProjectForm(({ closeProjectForm }) => closeProjectForm)
    const updateProject = useProjectsStore(({ updateProject }) => updateProject)
    const createProject = useProjectsStore(({ createProject }) => createProject)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const project = {
        ...useProjectsStore(({ projects }) => projects.find(project => project.id === openedProjectId)),
        ...useProjectForm(({ overload }) => overload),
    }

    const [label, setLabel] = useState(project?.label || '')

    useEffect(() => setLabel(project?.label || ''), [project?.label])

    const actions = <>
        <Button form={'project-' + openedProjectId} type="submit" disabled={isSubmitting}>
            {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {project?.id ? t('edit-project-submit') : t('create-project-submit')}
        </Button>
        <div>
            <DeleteProjectButton project={project} />
        </div>
    </>

    return <ResponsiveDrawer 
        actions={actions}
        open={!!openedProjectId}
        onClose={closeProjectForm}
        title={project?.id ? t('edit-project-title') : t('create-project-title')}
        description={project?.id ? t('edit-project-description') : t('create-project-description')}>
        <form id={'project-' + openedProjectId} className="grid items-start gap-4" onSubmit={handleSubmit}>
            <div className='grid gap-2'>
                <Label htmlFor='label'>{t('label')}</Label>
                <Input 
                    type="text" 
                    id="label"
                    value={label} 
                    onChange={(e) => setLabel(e.currentTarget.value)} />
            </div>
        </form>
    </ResponsiveDrawer>

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (!openedProjectId) return

        setIsSubmitting(true)

        const newState = { label }

        try {
            if (openedProjectId === 'new') {
                await createProject(newState)
                toast.success(t('message-project-created'))
            } else {
                await updateProject(openedProjectId, { ...project, ...newState })
                toast.success(t('message-project-updated'))
            }

            closeProjectForm()    
        } catch (error) {
            console.error(error)
            toast.error(t(openedProjectId === 'new' ? 'message-project-create-error' : 'message-project-update-error'))
        } finally {
            setIsSubmitting(false)
        }
    }
}

const DeleteProjectButton = ({ project }: { project: Partial<Project> }) => {
    const { t } = useTranslation()
    const deleteProject = useProjectsStore(({ deleteProject }) => deleteProject)
    const restoreProject = useProjectsStore(({ restoreProject }) => restoreProject)
    const closeProjectForm = useProjectForm(({ closeProjectForm }) => closeProjectForm)

    if (!project?.id) return null

    return <DeleteButton onDelete={handleDelete} label={t('delete-project-button')} />

    async function handleDelete() {
        if (!project?.id) return
        
        try {
            await deleteProject(project.id)
            closeProjectForm()
            toast.success(t('message-project-deleted'), {
                duration: 10000,
                action: {
                    label: t('undo'),
                    onClick: undoDelete
                }
            })
        } catch (error) {
            console.error(error)
            toast.error(t('message-project-delete-error'))
        }
    }

    async function undoDelete() {
        if (!project?.id) return

        try {
            await restoreProject(project.id)
            toast.success(t('message-project-restored'))
        } catch (error) {
            console.error(error)
            toast.error(t('message-project-restore-error'))
        }
    }
}