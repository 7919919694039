import './lib/i18n'
import './index.css'
import { createRoot } from 'react-dom/client'
import { StrictMode } from 'react'
import { ThemeProvider } from './components/theme-provider'
import { RouterContext } from './RouterContext'

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <ThemeProvider defaultTheme="system" storageKey="ui-theme">
            <RouterContext/>
        </ThemeProvider>
    </StrictMode>,
)