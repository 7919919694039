import { CirclePlus } from 'lucide-react'
import { useProjectForm } from '@/hooks/useProjectForm'
import { Button } from '../ui/button'
import { useBoardsStore } from '@/state/boards'
import { useTranslation } from 'react-i18next'

export const NewProjectButton = () => {
    const { t } = useTranslation()
    const selectedBoardId = useBoardsStore(({selectedBoardId}) => selectedBoardId)
    const openProjectForm = useProjectForm(({openProjectForm}) => openProjectForm)

    if (!selectedBoardId) return null

    return <Button 
        variant="outline" 
        className="text-base px-3 py-3 md:px-4 md:py-2 rounded-full md:rounded transition-opacity duration-300 ease-in-out bg-[hsl(var(--background))]/60 hover:bg-[hsl(var(--background))]"
        onClick={() => openProjectForm('new')}>
        <CirclePlus className="mr-0 md:mr-2 h-4 w-4" />
        <span className="hidden md:inline">{t('create-project-button')}</span>
    </Button>
}