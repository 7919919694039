import { useTranslation } from 'react-i18next'
import { ResponsiveDrawer } from '../ResponsiveDrawer'

const colors = [
    '#CBE896', '#AAC0AA', '#FCDFA6', '#A18276', '#F4B886', '#F5E6E8', '#D5C6E0', '#AAA1C8', '#967AA1', '#192A51',
    '#442B48', '#726E60', '#98B06F', '#B6DC76', '#DBFF76', '#688B58', '#90C290', '#AFE3C0', '#8963BA', '#54428E', 
    '#EABFCB', '#C191A1', '#A4508B', '#5F0A87', '#2F004F', '#2F3E46', '#354F52', '#52796F', '#84A98C', '#CAD2C5',
    '#49306B', '#635380', '#90708C', '#ACE4AA', '#E1CDB5', '#1C0118', '#370926', '#42113C', '#618B25', '#6BD425',
    '#6B4B3E', '#C49E85', '#FFD6AF', '#F8F4F9', '#BEA7E5', '#2D1E2F', '#F72C25', '#F7B32B', '#FCF6B1', '#A9E5BB',
    '#068D9D', '#53599A', '#6D9DC5', '#80DED9', '#AEECEF', '#C49799', '#BFB6BB', '#AFD0D6', '#A5D8FF', '#36393B',
    '#0B2027', '#40798C', '#70A9A1', '#CFD7C7', '#F6F1D1', '#89FC00', '#DC0073', '#00A1E4', '#F5B700', '#04E762'
]

export const ColorDrawer = (props: { open: boolean, onClose: () => void, onChange: (color: string) => void }) => {
    const { t } = useTranslation()

    return <ResponsiveDrawer
        title={t('board-background-color-drawer-title')}
        description={t('board-background-color-drawer-description')}
        open={props.open}
        onClose={handleClose}>
        
            <div className="h-[450px] overflow-x-hidden overflow-y-auto" id="scroller">
                <div className="columns-2 md:columns-3 gap-5 break-inside-avoid">
                    {colors.map((color) =>
                        <div
                            key={color}
                            onClick={() => handleClose(color)}
                            className="aspect-video w-full mb-5 rounded-lg cursor-pointer inline-block"
                            style={{ backgroundColor: color }} />
                    )}
                </div>
            </div>
    </ResponsiveDrawer>

    function handleClose(selectedColor?: string) {
        if (selectedColor) {
            props.onChange(selectedColor)
        }

        props.onClose()
    }
}