import { Task } from '@/state/tasks'
import { create } from 'zustand'

interface TaskFormState {
    overload: Partial<Task> | null
    openedTaskId: 'new' | string | null
    shouldMount: boolean
    openTaskForm: (taskId: string, overload?: Partial<Task>) => void
    closeTaskForm: () => void
}

export const useTaskForm = create<TaskFormState>((set) => ({
    overload: null,
    openedTaskId: null,
    shouldMount: false,
    openTaskForm: (taskId, overload) => set({ 
        openedTaskId: taskId, 
        overload: overload ? overload : null,
        shouldMount: true,
    }),
    closeTaskForm: () => {
        set({ openedTaskId: null, overload: null })
        // Give some time for animations before unmounting the form.
        setTimeout(() => set({ shouldMount: false }), 300)
    },
}))