import { useTaskForm } from '@/hooks/useTaskForm'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from '@/components/ui/select'
import { ResponsiveDrawer } from '../ResponsiveDrawer'
import { Subtask, Task, useTasksStore } from '@/state/tasks'
import { useEffect, useState } from 'react'
import { useProjectsStore } from '@/state/projects'
import { DeleteButton } from '../DeleteButton'
import { Textarea } from '../ui/textarea'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../ui/checkbox'
import { Separator } from '../ui/separator'
import { Loader2, Trash } from 'lucide-react'
import { generateUUID } from '@/lib/uuid'
import { toast } from 'sonner'

export const TaskForm = () => {
    const { t } = useTranslation()
    const openedTaskId = useTaskForm(({ openedTaskId }) => openedTaskId)
    const closeTaskForm = useTaskForm(({ closeTaskForm }) => closeTaskForm)
    const updateTask = useTasksStore(({ updateTask }) => updateTask)
    const createTask = useTasksStore(({ createTask }) => createTask)

    const task = {
        ...useTasksStore(({ tasks }) => tasks.find(task => task.id === openedTaskId)),
        ...useTaskForm(({ overload }) => overload),
    }
    const projects = useProjectsStore(({ projects }) => projects)

    const [label, setLabel] = useState(task?.label || '')
    const [description, setDescription] = useState(task?.description || '')
    const [projectId, setProjectId] = useState(task?.project_id || '')
    const [subtasks, setSubtasks] = useState<Task['subtasks']>(task?.subtasks || [])
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => setLabel(task?.label || ''), [task?.label, task?.id])
    useEffect(() => setProjectId(task?.project_id || ''), [task?.project_id, task?.id])
    useEffect(() => setDescription(task?.description || ''), [task?.description, task?.id])
    useEffect(() => setSubtasks(task?.subtasks || []), [task?.subtasks, task?.id])

    const actions = <>
        <Button form={'task-' + openedTaskId} type="submit" disabled={isSubmitting}>
            {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {task?.id ? t('edit-task-submit') : t('create-task-submit')}
        </Button>
        <div>
            <DeleteTaskButton task={task} />
        </div>
    </>

    return <ResponsiveDrawer 
        actions={actions}
        open={!!openedTaskId}
        onClose={handleClose}
        title={task?.id ? t('edit-task-title') : t('create-task-title')}
        description={task?.id ? t('edit-task-description') : t('create-task-description')}>
        <form className="" onSubmit={handleSubmit} id={'task-' + openedTaskId}>
            <div className="grid gap-6 items-start md:grid-cols-[2fr,1fr]">
                <div className="grid gap-4 items-start">
                    <div className="grid gap-2 mb-4">
                        <Label htmlFor="label">{t('label')}</Label>
                        <Input 
                            type="text" 
                            id="label"
                            value={label} 
                            onChange={(e) => setLabel(e.currentTarget.value)} />
                    </div>
                    <div className="grid w-full gap-2 mb-4">
                        <Label htmlFor="message">{t('description')}</Label>
                        <Textarea value={description} onChange={e => setDescription(e.currentTarget.value)} />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor='project'>{t('project')}</Label>
                        <Select onValueChange={setProjectId} value={projectId}>
                            <SelectTrigger className="">
                                <SelectValue placeholder={t('select-a-project')} />
                            </SelectTrigger>
                            <SelectContent>
                                {projects.map(project => <SelectItem key={project.id} value={project.id}>{project.label}</SelectItem>)}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className="grid w-full gap-2 mb-4">
                    <Subtasks 
                        list={subtasks} 
                        onAdd={handleSubtaskAdd} 
                        onChange={handleSubtaskChange} 
                        onRemove={handleSubtaskRemove}/>
                </div>
            </div>
        </form>
    </ResponsiveDrawer>

    function handleClose() {
        closeTaskForm()
        setLabel('')
        setDescription('')
        setProjectId('')
        setSubtasks([])
    }

    function handleSubtaskAdd(subtask: Partial<Subtask>) {
        // Casting the partial to a full subtask here, the only fields missing should be the timestamps (created_at, updated_at).
        setSubtasks([...subtasks, subtask as Subtask])
    }

    function handleSubtaskChange(subtask: Subtask) {
        setSubtasks(subtasks.map(s => s.id === subtask.id ? { ...s, ...subtask } : s))
    }

    function handleSubtaskRemove(subtask: Subtask) {
        if (subtask.isNew) {
            // Remove the subtask from the list if it's new and hasn't been saved yet.
            setSubtasks(subtasks.filter(s => s.id !== subtask.id))
            return
        }

        // Mark the subtask as removed if it's not new. We'll drop it from the DB after the form is submitted.
        setSubtasks(subtasks.map(s => s.id === subtask.id ? { ...s, isRemoved: true } : s))
    }

    async function handleSubmit (e: React.FormEvent) {
        e.preventDefault()
        if (!openedTaskId) return

        setIsSubmitting(true)

        const newState = { 
            label,
            description,
            subtasks,
            project_id: projectId,
        }
        
        try {
            if (openedTaskId === 'new') {
                await createTask(newState)
                toast.success(t('message-task-created'))
            } else {
                await updateTask(openedTaskId, { ...task, ...newState })
                toast.success(t('message-task-updated'))
            }

            closeTaskForm()    
        } catch (error) {
            // Error notifications should be handled by the store.
            console.error(error)
            toast.error(t(openedTaskId === 'new' ? 'message-task-create-error' : 'message-task-update-error'))
        } finally {
            setIsSubmitting(false)
        }        
    }
}

const Subtasks = (props: { 
    list: Task['subtasks'],  
    onAdd: (subtask: Partial<Subtask>) => void, 
    onRemove: (subtask: Subtask) => void,
    onChange: (subtask: Subtask) => void,
}) => {
    const { t } = useTranslation()
    const [newTaskLabel, setNewTaskLabel] = useState('')

    const filteredList = props.list.filter(st => !st.isRemoved)

    return <div>
        <div className="mb-4">{t('subtasks')}</div>
        {filteredList.map(subtask =>
            <div className="group flex items-center space-x-2" key={subtask.id}>
                <Checkbox 
                    id={subtask.id} 
                    checked={subtask.is_completed} 
                    onCheckedChange={(checked) => handleChange(subtask, !!checked)}/>
                <label
                    htmlFor={subtask.id}
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex-1">
                    {subtask.label}
                </label>
                <Button 
                    variant="ghost" 
                    className="md:opacity-0 md:group-hover:opacity-100 text-red-500"
                    onClick={() => props.onRemove(subtask)}>
                    <Trash className="h-4 w-4"/>
                </Button>
            </div>
        )}
        {!!filteredList.length && <Separator className="my-4"/>}
        <div className="flex items-center space-x-2">
            <Checkbox disabled className="pointer-events-none" />
            <Input 
                type="text" 
                value={newTaskLabel}
                onKeyDown={handleSubmit}
                onChange={(e) => setNewTaskLabel(e.currentTarget.value)} 
                placeholder={t('add-new-subtask')} />
        </div>
    </div>

    function handleSubmit(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key !== 'Enter') return
        
        e.stopPropagation()
        e.preventDefault()

        props.onAdd({ 
            id: generateUUID(),
            label: newTaskLabel, 
            is_completed: false,
            isNew: true,
        })

        setNewTaskLabel('')
    }

    function handleChange(subtask: Subtask, checked: boolean) {
        props.onChange({ ...subtask, is_completed: checked, hasChanged: true })
    }
}

const DeleteTaskButton = ({ task }: { task: Partial<Task> }) => {
    const { t } = useTranslation()
    const deleteTask = useTasksStore(({ deleteTask }) => deleteTask)
    const restoreTask = useTasksStore(({ restoreTask }) => restoreTask)
    const closeTaskForm = useTaskForm(({ closeTaskForm }) => closeTaskForm)

    if (!task?.id) return null

    return <DeleteButton onDelete={handleDelete} label={t('delete-task-button')} />

    async function handleDelete() {
        if (!task?.id) return

        try {
            await deleteTask(task.id)
            toast.success(t('message-task-deleted'), {
                action: {
                    label: t('undo'),
                    onClick: undoDelete
                },
                duration: 10000,
            })
            closeTaskForm()
        } catch (error) {
            console.error(error)
            toast.error(t('message-task-delete-error'))
        }
    }

    async function undoDelete() {
        if (!task?.id) return

        try {
            await restoreTask(task.id)
            toast.success(t('message-task-restored'))
        } catch (error) {
            console.error(error)
            toast.error(t('message-task-restore-error'))
        }
    }
}