import axios from 'axios'
import { createClient } from '@supabase/supabase-js'

export const db = createClient(
    import.meta.env.VITE_SUPABASE_URL, 
    import.meta.env.VITE_SUPABASE_ANON_KEY
)


export const api = axios.create({
    baseURL: 'http://localhost:3000/v1',
})