import { useUser } from '@/state/user'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../ui/dropdown-menu'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'

export const UserMenu = () => {
    const { t } = useTranslation()
    const user = useUser(({user}) => user)
    const profile = useUser(({profile}) => profile)
    
    return <DropdownMenu>
        <DropdownMenuTrigger>
            <Avatar>
                <AvatarImage src="" />
                <AvatarFallback>
                    {(profile?.name || profile?.email || '')[0]}
                </AvatarFallback>
            </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
            <DropdownMenuLabel>{user?.email}</DropdownMenuLabel>
            <DropdownMenuSeparator/>
            <DropdownMenuItem className="cursor-pointer">{t('settings')}</DropdownMenuItem>
            <DropdownMenuItem className="cursor-pointer" onClick={handleBillingClick}>{t('billing')}</DropdownMenuItem>
            <DropdownMenuSeparator/>
            <DropdownMenuItem asChild>
                <Link to="/logout" className="cursor-pointer">{t('logout')}</Link>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>

    function handleBillingClick() {
        window.location.href = import.meta.env.VITE_STRIPE_BILLING_PORTAL + '?prefilled_email=' + profile?.email
    }
}