import { useBoardsStore } from '@/state/boards'
import { useBoardForm } from '@/hooks/useBoardForm'
import { Button } from '../ui/button'
import { useTranslation } from 'react-i18next'

export const BoardsEmptyState = () => {
    const { t } = useTranslation()
    const isLoading = useBoardsStore(({isLoading}) => isLoading)
    const boards = useBoardsStore(({boards}) => boards)
    const openBoardForm = useBoardForm(({openBoardForm}) => openBoardForm)

    if (isLoading) return null
    if (boards.length) return null
    
    return <div className="flex flex-col items-center justify-center absolute top-[-5rem] bottom-0 right-0 left-0">
        <div className="w-40 h-40 bg-gray-800 rounded-full mb-8 text-8xl flex flex-col items-center justify-center">🦸🏻</div>
        <h1 className="text-3xl font-bold mb-2">{t('empty-state-boards-title')}</h1>
        <p className="text-lg text-gray-500 w-4/5 text-justify leading-6">
            {t('empty-state-boards-description')}
        </p>
        <Button 
            className="mt-8 text-lg bg-gray-800 hover:bg-gray-700" 
            onClick={() => openBoardForm('new')}>
            {t('create-first-board-button')}
        </Button>
    </div>
}