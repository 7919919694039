import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

type RouterContext = {
    userId?: string | null,
    isAuthInitiating: boolean,
}

const RootComponent = () => {
    return <div className="app-container relative relative">
        <Outlet/>
        {import.meta.env.DEV && <TanStackRouterDevtools />}
    </div>
}

export const Route = createRootRouteWithContext<RouterContext>()({
    component: RootComponent,
})