import { Loader } from '@/components/Loader'
import { ProjectsEmptyState } from '@/components/ProjectsEmptyState'
import { ProjectsList } from '@/components/ProjectsList'
import { TasksEmptyState } from '@/components/TasksEmptyState'
import { TasksList } from '@/components/TasksList'
import { Toaster } from '@/components/ui/sonner'
import { Wallpaper } from '@/components/Wallpaper'
import { useBoardsStore } from '@/state/boards'
import { useProjectsStore } from '@/state/projects'
import { useTasksStore } from '@/state/tasks'
import { createFileRoute, useParams } from '@tanstack/react-router'
import { useEffect } from 'react'

const BoardView = () => {
    const isProjectsStoreLoading = useProjectsStore(({ isLoading }) => isLoading)
    const isBoardsStoreLoading = useBoardsStore(({ isLoading }) => isLoading)
    const isTasksStoreLoading = useTasksStore(({ isLoading }) => isLoading)
    const isLoading = isBoardsStoreLoading || isTasksStoreLoading || isProjectsStoreLoading
    const selectedBoard = useBoardsStore(({ boards, selectedBoardId }) => boards.find(board => board.id === selectedBoardId))

    const { boardId } = useParams({ from: '/_authenticated/boards/$boardId' })
    useEffect(() => { useBoardsStore.getState().selectBoard(boardId) }, [boardId])

    if (!selectedBoard) {
        return null
    }

    if (isLoading) return <Loader />

    return <>
        <div className="px-1 relative flex-1 grow w-screen max-w-[800px] mx-auto">
            <ProjectsList />
            <TasksList />
            <ProjectsEmptyState />
            <TasksEmptyState />
            <Toaster position="bottom-center" richColors closeButton />
        </div>
        <Wallpaper board={selectedBoard} />
    </>
}

export const Route = createFileRoute('/_authenticated/boards/$boardId')({
    component: BoardView,
})
