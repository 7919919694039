import { useBoardForm } from '@/hooks/useBoardForm'
import { useBoardsStore } from '@/state/boards'
import { Folder, ChevronDown, Pencil, CirclePlus, House } from 'lucide-react'
import { Button } from '../ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../ui/dropdown-menu'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'

export const BoardList = () => {
    const { t } = useTranslation()
    const boards = useBoardsStore(({boards}) => boards)
    const openBoardForm = useBoardForm(({openBoardForm}) => openBoardForm)
    const selectedBoard = useBoardsStore(({selectedBoardId: id, boards}) => id ? boards.find(board => board.id === id) : null)

    if (!boards.length) return null

    return <div data-testid="boards-list">
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="link" className="text-xl font-bold px-0 text-dark dark:text-white">
                    {selectedBoard && <>
                        <Folder className="mr-2 h-5 w-5" strokeWidth={3} />
                        {selectedBoard?.label}
                    </>}
                    {!selectedBoard && <>
                        <House className="mr-2 h-5 w-5" strokeWidth={3} />
                        {t('homepage')}
                    </>}
                    <ChevronDown className="ml-2 mt-2 h-4 w-4 opacity-80" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" className="shadow-2xl board-selector-opened">
                <DropdownMenuItem asChild className="py-2 flex items-center justify-start group w-64 cursor-pointer">
                    <Link to="/"><House className="inline-flex text-lg h-4 w-4 mr-2" /> {t('homepage')}</Link>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuLabel>{t('recently-viewed-boards')}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {boards.map(board => 
                    <DropdownMenuItem 
                        asChild
                        key={board.id} 
                        className="py-2 flex items-center justify-between group w-64 cursor-pointer">
                            <Link to={`/boards/${board.id}`}>
                                {board.label}

                                <Button
                                    variant="link" 
                                    className="h-auto text-black dark:text-white p-0 text-lg md:opacity-0 md:group-hover:opacity-50 md:group-hover:hover:opacity-100 transition-opacity duration-200 ease-in-out"
                                    onClick={handleEditClick(board.id)}>
                                    <Pencil className="h-4 w-4" />
                                </Button>
                            </Link>
                    </DropdownMenuItem>
                )}
                {boards.length && <DropdownMenuSeparator/>}
                <DropdownMenuItem onClick={() => openBoardForm('new')} className="py-2 cursor-pointer">
                    <CirclePlus className="h-3 w-3 mr-3" /> {t('create-board-button')}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    </div>

    function handleEditClick (boardId: string) {
        return (e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            openBoardForm(boardId)
        }
    }
}