
import { useUser } from './state/user'
import { RouterProvider } from '@tanstack/react-router'
import { router } from './router'

export const RouterContext = () => {
    const userId = useUser(({user}) => user?.id)
    const isAuthInitiating = useUser(({isInitiating}) => isInitiating)

    return <RouterProvider router={router} context={{ userId, isAuthInitiating }} />
}