import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createFileRoute, Link } from '@tanstack/react-router'

import { db } from '@/services/api'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { CardContent } from '@/components/ui/card'
import { Bot } from 'lucide-react'
import { Loader } from '@/components/Loader'
import { replaceSupabaseErrorMessages } from '@/lib/supabaseErrorMessages'

export const Login = () => {
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    return <>
        {isLoading && <Loader />}
        <CardContent className="p-4 pt-0">
            <form onSubmit={handleSubmit} autoComplete="on" className="grid gap-2">
                <Input
                    type="email"
                    id="email"
                    value={email}
                    autoComplete='email'
                    placeholder={t('email-placeholder')}
                    onChange={(e) => setEmail(e.currentTarget.value)} />
                <Input
                    type="password"
                    id="password"
                    value={password}
                    autoComplete='current-password'
                    placeholder={t('password-placeholder')}
                    onChange={(e) => setPassword(e.currentTarget.value)} />
                <Button 
                    type="submit"
                    disabled={isLoading}
                    className={"mt-4 bg-yellow-400 hover:bg-yellow-600 text-black font-semibold " + (isLoading ? 'opacity-50' : '')}>
                    <Bot className="w-4 h-4 mr-2" />
                    {t('login-button')}
                </Button>
                <Link to="/lost-password" className="text-sm text-gray-400 hover:underline">
                    {t('forgot-password-link')}
                </Link>
                
                {error && <div className="text-red-500">{error}</div>}
            </form>

            <div className="mt-8 text-sm border-t pt-3">
                <Link to="/signup" className="text-gray-400 hover:underline">
                    {t('register')}
                </Link>
            </div>
        </CardContent>
    </>

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setIsLoading(true)
        const { error } = await db.auth.signInWithPassword({ email, password })
        setIsLoading(false)

        if (error) {
            setError(t(replaceSupabaseErrorMessages(error.message)))
        }

        // Just to clear up some potential confusion in the future ot to fellow devs:
        // After the login is successful, the user will be redirected to the homepage.
        // This happens via state/user.tsx because we observe the authentication status
        // from Supabase auth over there. That is why there seems to be no redirect here.
        // So if you want to change something about the redirect, you should look at the
        // state/user.tsx file.
        // 
        // Respectfully,
        // Marco
    }
}

export const Route = createFileRoute('/_auth/login')({
  component: Login,
})