import { LexoRank } from 'lexorank'
import { db } from '@/services/api'
import { create } from 'zustand'
import { useBoardsStore } from './boards'
import { useUser } from './user'
import { Task } from './tasks'

export interface Project {
    id: string
    label: string
    progress: number
    num_tasks_open: number
    num_tasks_in_progress: number
    num_tasks_done: number
    num_tasks_total: number
    board_id: string
    owner_id: string
    position: string
    created_at: Date
    updated_at: Date | null
    deleted_at: Date | null
}

interface ProjectsStore {
    projects: Project[]
    isLoading: boolean
    focusedProjectId: string | null
}

interface ProjectStoreActions {
    refresh: () => void
    createProject: (project?: Partial<Project>) => void
    updateProject: (projectId: string, project: Partial<Project>) => void
    deleteProject: (projectId: string) => void
    restoreProject: (projectId: string) => void
    moveProject: (projectId: string, destinationIndex: number, sourceIndex: number) => void
    updateTaskCounts: (projectId: string, previousStatus: Task['status'] | null, nextStatus: Task['status'] | null) => void
    focusProject: (projectId: string) => void
    clearFocus: () => void
}

export const useProjectsStore = create<ProjectsStore & ProjectStoreActions>((set, get) => ({
    focusedProjectId: null,
    isLoading: false,
    projects: [],
    refresh: async () => {
        const { selectedBoardId } = useBoardsStore.getState()
        const { user } = useUser.getState()

        set({ projects: [], isLoading: false })

        if (!user) {
            // throw Error('Cannot fetch boards without user')
            return
        }

        if (!selectedBoardId) {
            set({ projects: [], isLoading: false })
            return
        }

        set({ isLoading: true })
        
        let { data: projects } = await db.from('projects').select()
            .eq('owner_id', user.id)
            .eq('board_id', selectedBoardId)
            .neq('progress', 1) // Exclude completed projects.
            .is('deleted_at', null)
            .order('position', {ascending: true})
            .returns<Project[]>()

        if (!projects) projects = []

        set({ projects, isLoading: false })
    },

    focusProject: (projectId) => {
        if (!get().projects.find(p => p.id === projectId)) {
            throw Error('Project not found in store')
        }

        set({ focusedProjectId: projectId })
    },

    clearFocus: () => {
        set({ focusedProjectId: null })
    },

    createProject: async (project = {}) => {
        const selectedBoardId = useBoardsStore.getState().selectedBoardId

        if (!project.board_id) {
            if (!selectedBoardId) {
                throw Error('No board selected')
            }

            project.board_id = selectedBoardId
        }

        let position = ''
        if (get().projects.length) {
            const lastProject = get().projects[get().projects.length - 1]
            position = LexoRank.parse(lastProject.position).genNext().toString()
        } else {
            position = LexoRank.middle().toString()
        }

        project = {
            owner_id: useUser.getState().user?.id,
            position,
            ...project, 
            created_at: new Date() 
        }

        const { data } = await db.from('projects').insert(project).select().returns<Project[]>()
        const newProject = data?.[0]

        if (!newProject) {
            throw Error('Failed to create project')
        }

        set(({ projects }) => ({ projects: [ ...projects, newProject] }))
    },

    updateProject: async (projectId, project) => {
        project = { ...project, updated_at: new Date() }
        const { data: results } = await db.from('projects').update({
            label: project.label,
            position: project.position,
            updated_at: project.updated_at,
            deleted_at: project.deleted_at,
            num_tasks_open: project.num_tasks_open,
            num_tasks_in_progress: project.num_tasks_in_progress,
            num_tasks_done: project.num_tasks_done,
        }).eq('id', projectId).select().returns<Project[]>()

        const updatedProject = results?.[0]

        if (!updatedProject) {
            throw Error('Failed to update project')
        }

        set(({ projects }) => ({ projects: projects.map(t => t.id === projectId ? { ...t, ...updatedProject } : t) }))

        if (updatedProject.progress === 1) {
            // Remove the project from the list once it's completed but give some time for the state to be reflected in the UI.
            setTimeout(() => set(({ projects }) => ({ projects: projects.filter(p => p.id !== projectId) })), 500)
        }
    },

    deleteProject: async (projectId) => {
        // Use soft delete to keep the data around for a while.
        await get().updateProject(projectId, { deleted_at: new Date() })
    },

    restoreProject: async (projectId: string) => {
        await get().updateProject(projectId, { deleted_at: null })
    },

    moveProject: async (projectId, sourceIndex, destinationIndex) => {
        const projects = [ ...get().projects ]
        const project = projects.find(p => p.id === projectId)

        if (!project) {
            throw Error('Project not found in store')
        }

        projects.splice(sourceIndex, 1)
        projects.splice(destinationIndex, 0, project)

        // Persist the new order.
        set({ projects })

        const previousPosition = projects[destinationIndex - 1]?.position
        const nextPosition = projects[destinationIndex + 1]?.position

        let position = LexoRank.middle().toString()
        if (previousPosition && nextPosition) {
            position = LexoRank.parse(previousPosition).between(LexoRank.parse(nextPosition)).toString()
        } else if (previousPosition) {
            position = LexoRank.parse(previousPosition).genNext().toString()
        } else if (nextPosition) {
            position = LexoRank.parse(nextPosition).genPrev().toString()
        }

        // Update the project position.
        await get().updateProject(projectId, { position, })
    },

    updateTaskCounts(projectId, previousStatus, nextStatus) {
        const project = get().projects.find(p => p.id === projectId)

        if (!project) {
            throw Error('Project not found in store')
        }

        const fieldsByStatus = ['num_tasks_open', 'num_tasks_in_progress', 'num_tasks_done']
        if (previousStatus !== null) {
            // New tasks wont have a previous status
            project[fieldsByStatus[previousStatus] as keyof Project]--
        }
        if (nextStatus !== null) {
            // If tasks are deleted, they won't have a next status - duh.
            project[fieldsByStatus[nextStatus] as keyof Project]++
        }

        // Update the project with the new task counts but ensure they never go below 0.
        get().updateProject(projectId, {
            num_tasks_open: Math.max(0, project.num_tasks_open),
            num_tasks_in_progress: Math.max(0, project.num_tasks_in_progress),
            num_tasks_done: Math.max(0, project.num_tasks_done),
        })
    },
}))