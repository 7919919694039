import { useTasksStore, type Task } from '@/state/tasks'
import {
    Card,
    CardHeader,
    CardTitle,
  } from '@/components/ui/card'
import { useTaskForm } from '@/hooks/useTaskForm'
import { Circle, CircleCheck, CircleDot, SquareCheckBig } from 'lucide-react'

export const TaskCard = ({task}: {task: Task}) => {
    const openTaskForm = useTaskForm(({openTaskForm}) => openTaskForm)
    const updateTask = useTasksStore(({updateTask}) => updateTask)

    let partyStyle = ' '
    if (Number.isInteger(task.isTransitioningFromStatus) && task.status === 2) {
        partyStyle += 'border-green-500 shadow-[inset_0_0_30px_-15px_rgba(81,200,79,0.3),0_0_60px_-15px_rgba(81,200,79,0.3)]'
        partyStyle += ' bg-[url("/confetti.gif")] bg-repeat bg-center'
    }

    let borderStyle = 'border-slate-600/50 dark:border-slate-200/20 '
    if (task.status === 1) {
        borderStyle = 'border-yellow-500 dark:border-yellow-800'
    } else if (task.status === 2) {
        borderStyle = 'border-green-500 dark:border-green-800'
    }

    return <Card className={'border-2 relative overflow-hidden group mb-2 md:mb-4 transition duration-300 ease-in-out ' + partyStyle + borderStyle} onClick={() => openTaskForm(task.id)}>
        <CardHeader className="p-3 md:p-5">
            <CardTitle className="text-base md:text-lg flex justify-between items-center">
                <div className="flex-0">
                    {task.status === 0 && <Circle 
                        className="h-4 w-4 md:h-5 md:w-5 mr-2 md:mr-3 text-gray-400"
                        onClick={handleStatusChange(1, task)}/>}
                    {task.status === 1 && <CircleDot 
                        className="h-4 w-4 md:h-5 md:w-5 mr-2 md:mr-3 text-yellow-600"
                        onClick={handleStatusChange(2, task)}/>}
                    {task.status === 2 && <CircleCheck 
                        className="h-4 w-4 md:h-5 md:w-5 mr-2 md:mr-3 text-green-600"
                        onClick={handleStatusChange(0, task)}/>}
                </div>
                <div className="flex-1">{task.label}</div>
                <SubtaskStats task={task}/>
            </CardTitle>
        </CardHeader>
    </Card>

    function handleStatusChange (status: Task['status'], task: Task) {
        return (e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.stopPropagation()
            updateTask(task.id, { status })
        }
    }
}

const SubtaskStats = ({task}: {task: Task}) => {
    const numSubtasks = task.subtasks.length
    const numCompletedSubtasks = task.subtasks.filter(subtask => subtask.is_completed).length
    const progress = numSubtasks === 0 ? 0 : numCompletedSubtasks / numSubtasks

    if (numSubtasks === 0) {
        return null
    }

    return <div className="flex items-center text-xs md:text-sm text-gray-600 dark:text-gray-400 mr-4 whitespace-nowrap">
        <SquareCheckBig className="h-4 w-4 mr-1"/>
        <span className="opacity-80">{Math.round(progress * 100)} %</span>
        <span className="opacity-40 dark:opacity-30 ml-1 md:ml-2">({numCompletedSubtasks}/{numSubtasks})</span>
    </div>
}